import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import { Seo } from "../components/common"
import { PageProps } from "@/definitions"

const BlogPostTemplate: React.FC<PageProps> = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  // Extract gatsbyImageData
  const coverImageData = getImage(post.frontmatter.thumbnail?.childImageSharp?.gatsbyImageData);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="mx-auto max-w-prose">
        {coverImageData && (
          <GatsbyImage
            image={coverImageData}
            alt={post.frontmatter.title}
            className="w-full h-auto mb-8 rounded-xl"
          />
        )}
      </div>
      <article itemScope itemType="http://schema.org/Article" className="mx-auto max-w-prose">
        <header className="mb-8">
          <h1
            className="font-black text-skin-fg text-4xl md:text-6xl mb-2"
            itemProp="headline"
          >
            {post.frontmatter.title}
          </h1>
          <p className="text-sm text-gray-400 mt-4">
            {post.frontmatter.date}
          </p>
        </header>

        <section itemProp="articleBody" className="text-gray-200 prose-lg">
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
      </article>
      {/* ... navigation ... */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
